<template>
  <div class="about">
    <section class="w3l-about-breadcrum">
      <div class="breadcrum-bg py-sm-5 py-4">
        <div class="container py-lg-3 py-2">
          <h2>汇盛金丰</h2>
          <div class="desc">
            <p>持续为企业物业方赋能和创造价值</p>
          </div>
        </div>
      </div>
    </section>

    <section class="tab-bar">
      <el-tabs v-model="activeName">
        <el-tab-pane label="公司简介" name="introduce">
          <div class="inside_con">
            <p style="text-indent: 2em; line-height: 2em">
              <strong>广州市汇盛金丰科技企业孵化器有限公司</strong
              >（简称：汇盛金丰），注册资金1000万 ， 我司专注于产业 +
              商业品牌运营平台，同时为广州目前规模最大的服务型国家级孵化器之一。
            </p>
            <p style="text-indent: 2em; line-height: 2em">
              截止2019年底，我司及旗下广州市德企云网商业招商有限公司，自营及代理项目已超过50个
              。
            </p>
            <p style="text-indent: 2em; line-height: 2em">
              汇盛金丰2020年将以北京、上海、深圳、广州为中心，覆盖150个城市的全国化战略布局，通过多样化、定制化场景化的服务持续为企业或物业方赋能或创造价值。
            </p>
            <p style="text-align: center; margin-top: 20px">
              <img class="weimg" src="@/assets/images/g8.png" border="0" />
            </p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="服务项目" name="project">
          <div class="inside_con">
            <p style="margin-bottom: 5px; line-height: 2em">
              <strong>财税服务服务内容：</strong>
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              <strong>一、财税服务的业务范围</strong>
            </p>
            <p style="line-height: 2em">1、拎包办公&nbsp;</p>
            <p style="line-height: 2em">2、妈妈财税&nbsp;&nbsp;</p>
            <p style="line-height: 2em">3、企业服务</p>
            <p style="margin-bottom: 5px; line-height: 2em">&nbsp;</p>
            <p style="margin-bottom: 5px; line-height: 2em">
              <strong>二、财税服务的工作时间</strong>
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              业主业务量而定，一般每月集中记帐一次，业务量大的每星期一次，或由双方根据具体情况商定。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">&nbsp;</p>
            <p style="margin-bottom: 5px; line-height: 2em">
              <strong>三、财税服务的人员安排</strong>
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              我公司派出会计专业人员，并保证业主在与本公司签定的服务合同期内绝不中断记帐服务。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              四、相应的税务服务
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              我公司记帐人员可为客户办理纳税申报以及纳税事务咨询服务。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">&nbsp;&nbsp;</p>
            <p style="margin-bottom: 5px; line-height: 2em">
              <strong>五、业主应提供的基本条件</strong>
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              1、配备合格出纳人员办理相关业务；
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              2、提供必要的工作条件；
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              3、各有关业务人员予以必要的配合。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">&nbsp;&nbsp;</p>
            <p style="margin-bottom: 5px; line-height: 2em">
              <strong>一、财务公司收费标准：</strong>
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              财税服务收费标准：
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              A、建筑业及其他工程行业、租赁业、饮食业及部分服务业(营业税纳税人)：350-500元/月
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              B、商业批发、零售业、现代服务业(增值税小规模纳税人)：400—600元/月
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              C、工业生产、制造、维修、运输业(增值税小规模纳税人)：400-800元/月
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              D、批发零售、生产制造、运输业、现代服务业等(增值税一般纳税人)：600—1000元/月
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              E、外资、高新技术企业：600—1500元/月
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">&nbsp;&nbsp;</p>
            <p style="margin-bottom: 5px; line-height: 2em">
              <strong>二、财务外包准备程序：</strong>
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              1.与潜在客户沟通，了解客户合作意向。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              2.为了更周到地为客户提供良好的服务，深入了解客户公司以下内容：公司成立的时间、所属行业、营业收入状况、主要流转税为增值税的，是增值税一般纳税人还是小规模纳税人，或营业税的纳税人。企业所得税是查账征收还是核定征收。公司是外资、中外合资、内资等。公司的员工人数。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              3.报价。根据第二条了解客户情况后，结合公司的服务定价政策，初步向客户提供报价。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              4.登记客户意向表。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              5.跟踪客户至少3次，并与客户达成或上门作进一步沟通计划。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">&nbsp;&nbsp;</p>
            <p style="margin-bottom: 5px; line-height: 2em">
              <strong>签订协议&nbsp;</strong>
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              1.与客户达成合作意向后，就客户提出的服务开始日期、服务期限、服务内容、服务价格等初步协议转交客户。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              2.若与客户达成一致意见，则签订服务协议，否则将服务条款修改后直到客户满意后签订最终协议。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">&nbsp;&nbsp;</p>
            <p style="margin-bottom: 5px; line-height: 2em">
              <strong>执行协议</strong>
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              1.财务顾问与客户相关人员初步确定第一次服务前的工作准备。服务前的准备工作具体内容如下：
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              （1）确定客户专职或兼职出纳人员。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              （2）财务顾问指导出纳建立内部管理用现金与银行存款日记账。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              （3）指导客户日常资金暂支单和费用报销单等单据的填制和办理工作。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              （4）要求客户提供：营业执照与税务登记证复印件，税务局批准的税种及税率表复印件。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              （5）要求客户提供客户单位所属税务局地址、税务专管员姓名及联系方式。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              （6）客户出纳人员每月25日将费用报销单、暂支单、发票、银行往来收据、工资及社保金明细表、原材料、半成品、产成品、库存商品等出入库单、购销发票、经济合同等资料快递锐卓公司指定的财务顾问。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              2.做账。每月25日至30日财务顾问整理发票并及时做账。做账过程中，对不确定的问题如发票等资料短缺或内容不清楚的问题及时与客户方沟通。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              3.报税。每月1号至10号填制税务报表资料并指导客户出纳划转税款。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              4.需到客户税务局国、地税报税的，由财务顾问或客户出纳上门报税。实行网上申报的，进行网上申报。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              5.财务顾问将税单、财务报表、纳税申报表、记账凭证等资料快递给客户公司。
            </p>
            <p style="margin-bottom: 5px; line-height: 2em">
              6.财务顾问就税务局提出的问题及最新财税政策及时反馈给客户
            </p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="联系我们" name="contact">
          <div class="inside_con">
            <p style="line-height: 2em">
              广州市汇盛金丰科技企业孵化器有限公司<br />
            </p>
            <p style="line-height: 2em">手机：18145701510</p>
            <p style="line-height: 2em">邮箱：hsjf6688@126.com</p>
            <p style="line-height: 2em">网址：www.monfinance.cn</p>
            <p style="line-height: 2em">地址：广州市天河区沙太南路85号216室</p>
            <p style="line-height: 2em; margin-top: 32px">
              <img class="qrcode-all" src="@/assets/images/qrcode-all.jpg" />
            </p>
          </div>
        </el-tab-pane>
      </el-tabs>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "",
    };
  },
  created() {
    const queryName = this.$route.query.item;
    this.getDetail(queryName);
  },
  watch: {
    $route: {
      handler() {
        this.getDetail(this.$route.query.item);
        //深度监听，同时也可监听到param参数变化
      },
      deep: true,
    },
    activeName(newVal, oldVal) {
       this.$router.push({
        query: {
          item: newVal,
        },
      });
    }
  },
  methods: {
    getDetail(queryName) {
      switch (queryName) {
        case "introduce":
          this.activeName = queryName;
          break;
        case "project":
          this.activeName = queryName;
          break;
        case "contact":
          this.activeName = queryName;
          break;
        default:
          this.activeName = "introduce";
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.w3l-about-breadcrum .breadcrum-bg h2 {
  // font-size: 36px;
  font-size: 42px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: #fff;
  margin-bottom: 8px;
}

.w3l-about-breadcrum .breadcrum-bg .desc {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: #fff;
}

.my-title {
  font-size: 36px;
  line-height: 44px;
  color: var(--title-color);
  text-transform: capitalize;
  font-weight: 600;
}

.w3l-features-5 .features h5 {
  font-weight: normal;
}

.contant11-top-bg.py-5 {
  padding-top: 0 !important;
}

.tab-bar {
  position: relative;
  max-width: 1140px;
  margin: 0 auto;
  padding-top: 29px;

  .inside_con {
    width: 1000px;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 768px) {
  .tab-bar {
    padding: 0 20px;
    .inside_con {
      width: 100%;
      .qrcode-all,
      .weimg {
        width: 100%;
      }
    }
  }
}
</style>
